import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../components/layout";

const OurTeamPage = () => {
  const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(
          relativePath: {
            eq: "stock-photo-face-of-handsome-hispanic-man-looking-at-camera-1557421448.jpg"
          }
        ) {
          childImageSharp {
            gatsbyImageData(
              width: 500
              quality: 50
              webpOptions: { quality: 70 }
              placeholder: BLURRED
            )
          }
        }
      }
    `
  );
  const image = getImage(placeholderImage);

  return (
    <Layout>
      <div>
        <h1>Πρόσωπα της VS</h1>
        <div>
          <section className="flex flex-row w-full border-b border-vs-grey pb-5 mt-5">
            <div className="flex flex-col mr-5">
              <GatsbyImage
                image={image}
                className="border border-vs-yellow rounded-lg"
              />
            </div>
            <div className="flex flex-col ml-5">
              <h2>Κουτράκης Ιωάννης</h2>
              <span>CEO</span>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime
                minima velit repellendus nostrum dolorem dicta laudantium
                numquam natus suscipit, minus doloribus eos tempora expedita qui
                nihil nam placeat, saepe iure?
              </p>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default OurTeamPage;
